import React, { useEffect, useState } from 'react';
import Loading from '../Loading';

// Helper to add scripts to our page
const insertScript = (src, id, parentElement) => {
  const script = window.document.createElement('script');
  script.async = true;
  script.src = src;
  script.id = id;
  script.dataset.pageId = '/comments/';
  script.dataset.noFonts = false;
  parentElement.appendChild(script);
  return script;
};

// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};

const Commento = ({ id }) => {
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return;
    }
    const { document } = window;
    // In case our #commento container exists we can add our commento script
    if (document.getElementById('commento')) {
      insertScript('https://cdn.commento.io/js/commento.js', 'commento-script', document.body);
    }

    setLoading(false);

    // Cleanup; remove the script from the page
    // eslint-disable-next-line consistent-return
    return () => removeScript('commento-script', document.body);
  }, [ id ]);

  return (
    <>
      { loading && <Loading /> }
      <div id="commento" />
    </>
  );
};

export default Commento;
