import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Commento from '../components/reviews/Commento';
import ReviewsSlider from '../components/reviews/Slider';
import SEO from '../components/SEO';

const CommentPage = ({ data }) => {
  const { allAirtable: { edges } } = data;

  // Remove country where Country was set to "." in Airtable.
  // Gatsby doesn't allow optional fields so Airtable needs to hold some data
  const filteredEdges = edges.map(edge => {
    if (edge.node.data.Country === '.') {
      return ({
        node: {
          data: {
            ...edge.node.data,
            Country: '',
          },
        },
      });
    }
    return edge;
  });

  return (
    <Layout>
      <SEO
        title="Comments"
      />
      <ReviewsSlider reviews={ filteredEdges } />
      <Commento />
    </Layout>
  );
};

export default CommentPage;

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "Reviews" } }) {
      edges {
        node {
          data {
            Name
            Country
            Review
          }
        }
      }
    }
  }
`;
