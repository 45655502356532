import React from 'react';
import Review from './Review';

const ReviewsSlider = ({ reviews }) => (
  <div className="reviews">
    { reviews.map(({ node: review }, key) => (
      <div key={ `item-${key * key}` } className="reviews__item">
        <Review review={ review } />
      </div>
    )) }
  </div>
);

export default ReviewsSlider;
