import React from 'react';

const Review = ({ review }) => (
  <div className="review">
    <div className="review__inner">
      <div className="review__content">{ review.data.Review }</div>
      <div className="review__meta">
        { review.data.Name }
        { review.data.Country ? ` - ${review.data.Country}` : '' }
      </div>
    </div>
  </div>
);

export default Review;
